<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
      >
        <div class="layout-logo">
          <router-link :to="$constants.URL_ERR.REDIRECT">
            <img
              style="width: 160px; height: 187px; border-radius: 10px"
              alt="Logo"
              :src="logo"
            />
          </router-link>
        </div>
        <AppProfile />
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
    </transition>
    <div class="admin-layout layout-main">
      <router-view />
    </div>
    <Toast />
    <AppFooter />
  </div>
</template>
<script>
import AppTopBar from "./layout/Topbar";
import AppProfile from "./layout/Profile";
import AppMenu from "./layout/Menu";
import AppFooter from "./layout/Footer";
//import gql from "graphql-tag";
export default {
  name: "AdminLayout",
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    logo() {
      return this.layoutColorMode === "dark"
        ? "../../../assets/layout/images/logo.png"
        : "../../../assets/layout/images/logo.png";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  mounted() {
    document.body.style.zoom = "80%"; //zoom web lại 80%
    var open = localStorage.getItem("isOpen");
    if (open === undefined || !open) {
      this.staticMenuInactive = false;
    } else {
      this.staticMenuInactive = true;
    }
    this.menuService();
  },
  watch: {
    $route() {
      this.menuActive = false;
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          this.overlayMenuActive = !this.overlayMenuActive;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      // var open = localStorage.getItem('isOpen');
      // if (open === undefined  || !open) {
      //   localStorage.setItem('isOpen', true);
      // } else localStorage.setItem('isOpen', false);
      // console.log('event', event);
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
        if (this.$route.path !== event.item.to) {
          //var info = this.$router.resolve(event.item.to);
          window.location.href = event.item.to
         // this.$router.push({ path: event.item.to,params: {reload: true} });
          /*console.log("infoinfoinfo:",info);
          console.log("info.route.name:",info.route.name);
          this.$router.push({name: info.route.name, params: {reload: true}})*/
        }
      }
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
    async menuService() {
      //var listService = await this.$CoreService.getService();
      var uid_user = this.$store.getters.user;
      //var accountUser = this.$store.getters.accountUser;
      //lấy lấy uid_user
      //var account = this.$store.getters.accountUser;
      /*var account = await this.$CoreService.getAccountByUidFirebase(uid_admin);
        var uid_user = (account) ? account.uid:null;*/
      var users = await this.$CoreService.getUsersById(uid_user);
      //console.log("account:",account);
      // console.log("uid_useruid_user:",uid_user);
      //console.log("uid_useruid_useraccountUser:",accountUser);
      if (!uid_user) {
        return false;
      }

      var arr_menu = [];
      var menu_education = [];
      /*if (listService && listService.length > 0){
        for (var i=0;i<listService.length;i++){
          if(listService[i].type == 5){
            menu_education.push({
              label: listService[i].name, icon: 'record-icon', to: '/admin/list-register/list/'+listService[i].id, id: 'list_register_'+listService[i].id
            })
          }
          arr_menu.push(
              {
                label: listService[i].name, icon: 'record-icon', to: '/admin/list-service/list/'+listService[i].id, id: 'list_service_'+listService[i].id
              }
          );
        }
      }*/

      //check điều kiện supper admin
      if (users.role == this.$constants.ROLE_ACCOUNT.SUPPER_ADMIN) {
        var where_controller = {
          active: { _eq: true },
          deleted: { _eq: false },
          parent_tab: { _eq: true },
          _key: {_nin: ["add_user_to_role"]}
        };
        var order_by_controller = { ordinal: "desc" };
        var controller_list_supp = await this.$CoreService.getListData(
          "controller",
          where_controller,
          null,
          null,
          order_by_controller
        );

        var listMenuSupp = [];
        for (var s = 0; s < controller_list_supp.length; s++) {
          var itemMenuSupp = {
            label: controller_list_supp[s].name,
            icon: controller_list_supp[s].icon,
          };
          if (!this.$commonFuction.isEmpty(controller_list_supp[s].url)) {
            itemMenuSupp.to = controller_list_supp[s].url;
            itemMenuSupp.id = controller_list_supp[s]._key;
          }

          //(0:ko là dv,1:Quản lý dv,2:Đơn đăng ký đào tạo)
          if (this.$commonFuction.isEmpty(controller_list_supp[s].url)) {
            switch (controller_list_supp[s].tab_service) {
              case 0:
                if (
                  controller_list_supp[s].childs &&
                  controller_list_supp[s].childs.length > 0
                ) {
                  var list_menu_child_supp = controller_list_supp[s].childs;
                  var data_list_child_supp = [];
                  // console.log(list_menu_child_supp);
                  for (var cs = 0; cs < list_menu_child_supp.length; cs++) {
                    data_list_child_supp.push({
                      label: list_menu_child_supp[cs].name,
                      icon: list_menu_child_supp[cs].icon,
                      to: list_menu_child_supp[cs].url,
                      id: list_menu_child_supp[cs]._key,
                    });
                  }
                  itemMenuSupp.items = data_list_child_supp;
                }
                break;
              case 1:
                itemMenuSupp.items = arr_menu;
                break;
              case 2:
                itemMenuSupp.items = menu_education;
                break;
              default:
              // code block
            }
          }

          listMenuSupp.push(itemMenuSupp);
        }
        this.menu = listMenuSupp;
      } else {
        var group_user = await this.$CoreService.getListControllerGroupUser(
          uid_user
        );
        console.log("group_usergroup_user:",group_user)
        var check_dulicate_menu = {}
        var listMenu = [];
        var listMenuSub = [];
        var list_parent_id = []
       // var list_parent_menu = []
        if (group_user && group_user.length > 0) {
          for (var h = 0; h < group_user.length; h++) {
          var group_user_obj = group_user[h];
          // console.log("group_user_obj",group_user_obj)
          if (group_user_obj) {
            if (group_user_obj.group) {
              if (group_user_obj.group.group_roles) {
                var controller_list = group_user_obj.group.group_roles;
                for (var m = 0; m < controller_list.length; m++) {
                  if (controller_list[m].role) {
                    if (controller_list[m].role.controller) {
                      if (
                          controller_list[m].role.controller.parent_id == null ||
                          controller_list[m].role.controller.parent_id ==
                          undefined
                      ) {
                        var getItemController =
                            controller_list[m].role.controller;
                        if (this.$commonFuction.isEmpty(check_dulicate_menu[getItemController.id])) {
                          check_dulicate_menu[getItemController.id] = 1
                          listMenu.push(getItemController)
                        }
                        console.log("getItemControllergetItemController:", getItemController)
                        var itemMenu = {
                          label: getItemController.name,
                          icon: getItemController.icon,
                          is_parent: getItemController.is_parent,
                          id_tmp: getItemController.id,
                        };
                        if (
                            !this.$commonFuction.isEmpty(getItemController.url)
                        ) {
                          itemMenu.to = getItemController.url;
                          itemMenu.id = getItemController._key;
                        }

                        //(0:ko là dv,1:Quản lý dv,2:Đơn đăng ký đào tạo)
                        /*if (
                            this.$commonFuction.isEmpty(getItemController.url)
                        ) {
                          // console.log("555555555555555555555555555555:",getItemController)
                          switch (getItemController.tab_service) {
                            case 0:
                              if (
                                  getItemController.childs &&
                                  getItemController.childs.length > 0
                              ) {
                                var list_menu_child = getItemController.childs;
                                // console.log(list_menu_child);
                                var data_list_child = [];
                                for (
                                    var c = 0;
                                    c < list_menu_child.length;
                                    c++
                                ) {
                                  data_list_child.push({
                                    label: list_menu_child[c].name,
                                    icon: list_menu_child[c].icon,
                                    to: list_menu_child[c].url,
                                    id: list_menu_child[c]._key,
                                  });
                                }
                                itemMenu.items = data_list_child;
                              }
                              break;
                            case 1:
                              itemMenu.items = arr_menu;
                              break;
                            case 2:
                              itemMenu.items = menu_education;
                              break;
                            default:
                              // code block
                          }
                        }*/
                        //listMenu.push(itemMenu);
                      } else {
                        console.log("dddddddddddddddddddd", controller_list[m].role.controller)
                        var id_controller =controller_list[m].role.controller.id
                        var parent_id_controller =controller_list[m].role.controller.parent_id
                        var parent_menu =controller_list[m].role.controller.parent_menu
                        if (this.$commonFuction.isEmpty(check_dulicate_menu[id_controller])) {
                          check_dulicate_menu[id_controller] = 1
                          listMenuSub.push(controller_list[m].role.controller);
                        }
                        list_parent_id.push(parent_id_controller);


                        if (!this.$commonFuction.isEmpty(parent_menu)) {
                          if (this.$commonFuction.isEmpty(check_dulicate_menu[parent_menu.id])) {
                            check_dulicate_menu[parent_menu.id] = 1
                            listMenu.push(parent_menu)
                          }
                        }
                        //console.log("parent_menu:",parent_menu)
                      }
                    }
                  }
                }
              }
              if (listMenuSub.length){
                listMenuSub.sort((a, b) => {
                  return b.ordinal - a.ordinal; // Sử dụng a.key - b.key để sắp xếp theo trường 'key'
                });
              }
                if (listMenu.length){
                  listMenu.sort((a, b) => {
                    return b.ordinal - a.ordinal; // Sử dụng a.key - b.key để sắp xếp theo trường 'key'
                  });
                }
                console.log("listMenulistMenu:",listMenu)
                console.log("listMenuSublistMenuSub:",listMenuSub)
              var listMenuConvert = []
                if (listMenu.length > 0) {
                  for (let index = 0; index < listMenu.length; index++) {
                    const element = listMenu[index];
                    console.log("elementelement:",element)


                      var itemMenuP = {
                        label: element.name,
                        icon: element.icon,
                        is_parent: element.is_parent,
                        id_tmp: element.id,
                      };
                      if (
                          !this.$commonFuction.isEmpty(element.url)
                      ) {
                        itemMenuP.to = element.url;
                        itemMenuP.id = element._key;
                      }
                    if (element.is_parent == 1) {
                      var items = [];
                      for (let ind = 0; ind < listMenuSub.length; ind++) {
                        const elem = listMenuSub[ind];
                        // console.log("AAAA",elem)
                        if (elem.parent_id == element.id) {
                          items.push({
                            label: elem.name,
                            icon: elem.icon,
                            to: elem.url,
                            id: elem._key,
                          });
                        }
                      }
                      if (items.length > 0) {
                        itemMenuP.items = items;
                      }

                    }
                    listMenuConvert.push(itemMenuP)
                  }
                }
              }
            }
          }
        }
        // console.log("listMenu",listMenu);
        this.menu = listMenuConvert;
      }

      /*console.log("controller_list:",controller_list);
      console.log("listService",listService);
      this.menu = [
          {
            label: 'Quản lý người dùng', icon: 'fas fa-users',
            items: [
              {
                label: 'Chủ thú cưng', icon: 'record-icon', to: '/admin/list-users', id: 'list_user'
              },
              {
                label: 'Người chăm sóc', icon: 'record-icon', to: '/admin/list-view_user_caregiver', id: 'list_users_caregiver'
              },
              {
                label: 'Người người dùng hệ thống', icon: 'record-icon', to: '/admin/list-view_admin', id: 'list_admin'
              },
            ]
          },
          {
            label: 'Quản lý dịch vụ', icon: 'fas fa-paw', /!*to: '/admin/list-service', id: 'list_service'*!/
            items:arr_menu
          },
          {
            label: 'Quản lý cửa hàng', icon: 'fas fa-map-marked-alt', to: '/admin/list-store', id: 'list_store'
          },
          {
            label: 'Danh mục sản phẩm', icon: 'fas fa-list-ol', to: '/admin/list-category', id: 'list_category'
          },
          {
            label: 'Danh sách sản phẩm', icon: 'fas fa-cube', to: '/admin/list-product', id: 'list_produt'
          },
          {
            label: 'Danh sách lịch hẹn', icon: 'fas fa-calendar-alt', to: '/admin/list-appointment', id: 'list_appointment'
          },
          {
            label: 'Danh sách đơn hàng', icon: 'fas fa-shopping-cart', to: '/admin/list-orders', id: 'list_orders'
          },
          /!*{
            label: 'Danh sách đơn hàng', icon: 'fas fa-list-alt', to: '/admin/list-orders', id: 'list_orders'
          },*!/
          {
            label: 'Quản lý thông báo', icon: 'fas fa-bell', to: '/admin/list-notification', id: 'list_notification'
          },
          {
            label: 'Đơn đăng ký đào tạo', icon: 'fas fa-user-graduate',
            items: menu_education
          },
          {
            label: 'Yêu cầu rút tiền', icon: 'fas fa-cog', to: '/admin/list-withdrawal', id: 'list_withdrawal'
          },
          {
            label: 'Thống kê báo cáo', icon: 'fas fa-file-alt', to: '/admin/list-statistical', id: 'list_statistical'
          },
          {
            label: 'Quản lý chính sách', icon: 'fas fa-file-alt', to: '/admin/list-policy/edit/1', id: 'edit_policy'
          },
          {
            label: 'Lịch sử ví', icon: 'fas fa-cog', to: '/admin/list-wallet', id: 'list_wallet'
          },
          {
            label: 'Quản lý đánh giá', icon: 'fas fa-cog', to: '/admin/list-rate', id: 'list_rate'
          },
          {
            label: 'Cài đặt', icon: 'fas fa-cog', to: '/admin/list-setting', id: 'list_setting'
          },
        ];*/
    },
  },
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      //menu:this.menuService(),
      menu: [],
    };
  },
};
</script>

<style lang="scss">
.manager-title {
  color: #1d3118;
  text-transform: capitalize;
  font-size: 24px;
  margin-bottom: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  font-weight: 700;
}
.p-column-filter {
  width: 100%;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-paginator {
  .p-paginator-current {
    margin-left: 10px;
    margin-right: auto;
  }

  .p-dropdown {
    margin-left: auto;
    margin-right: 10px;
  }
}

.p-datatable.p-datatable-responsive {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
    border: 0 none;
    background: #fff;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980b9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #ddd;
}
.show {
  display: block;
}
</style>
