<template>
	<div class="layout-profile">
		<!-- <div>
			<img :src="avatar" alt="" />
		</div> -->
		
		<a href="#" class="username username-custom">{{username}}</a>
		
		<!-- <button class="p-link layout-profile-link" @click="onClick">
			<span class="username">Claire Williams</span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link"><i class="pi pi-fw pi-user"></i><span>Account</span></button></li>
                <li><button class="p-link"><i class="pi pi-fw pi-inbox"></i><span>Notifications</span><span class="menuitem-badge">2</span></button></li>
                <li><button class="p-link"><i class="pi pi-fw pi-power-off"></i><span>Logout</span></button></li>
            </ul>
        </transition> -->
		
	</div>
</template>

<script>
	//import DataServices from '@/core/DataServices'
	//import $ from 'jquery'
	// import store from './store'
	export default {
		data() {
			return {
				expanded: false,
				data: {},
				username: '',
				avatar: ''
			}
		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			}
		},
		created() {
			this.$forceUpdate()
		},
		// apollo: {
		// 	data: {
		// 		query() { return DataServices.getById('admin') },
		// 		variables() {
		// 			return {
		// 			objId: this.$store.getters.user
		// 			}
		// 		},
		// 		update(data) {
		// 			// console.log('abc', data)
		// 			if (data.admin) {
		// 				this.$store.commit('setRole', data.admin[0].role)
		// 				this.username = data.admin[0].fullname
		// 				this.avatar = data.admin[0].avatar
		// 				if (data.admin[0].role == 'company_admin') {
		// 					$('#list_admin').hide()
		// 					$('#list_category').hide()
		// 					$('#list_suggestion').hide()
		// 					$('#list_policy').hide()
		// 					$('#list_maxim').hide()
		// 					// $('#list_idea').hide()
		// 					$('#list_notification').hide()
		// 				}
		// 			} else {
		// 				this.username = 'Admin'
		// 				this.avatar = ''
		// 			}
					
		// 		},
		// 		skip(){
		// 			if (this.$store.getters.user) {
		// 				return false
		// 			} else {
		// 				return true
		// 			}
		// 		}
		// 	},
		// }
	}
</script>

<style scoped>
	.username-custom{
		color: white;
	}
</style>