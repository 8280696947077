<template>
	<ul v-if="items">
		<template v-for="(item,i) of items">
			<li :key="i" :class="[{'active-menuitem': (activeIndex === i && !item.to)|| routeArr.includes(item.to)}]">
				<div v-if="item.items && root===true" class='arrow'></div>
				<a :id="item.id" v-if="item.to" :href="item.to" :class="[{'active-route': activeIndex === i || routeArr.includes(item.to)}]" @click="onMenuItemClick($event,item,i)" :target="item.target" exact>
					<i :class="(item?.icon || 'fa fa-circle-o') + ' mr-2'"></i>
					<span class="custom-sub">{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</a>
				<a v-if="!item.to" :href="item.url||'#'" @click="onMenuItemClick($event,item,i)" target="item.target">
					<i :class="(item?.icon || 'fa fa-circle-o') + ' mr-2'"></i>
					<span>{{item.label}}</span>
					<i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
					<span v-if="item.badge" class="menuitem-badge">{{item.badge}}</span>
				</a>
				<transition name="layout-submenu-wrapper">
					<AppSubmenu v-show="activeIndex === i" :items="item.items" @menuitem-click="$emit('menuitem-click', $event)" class="test-ahihi"></AppSubmenu>
				</transition>
			</li>
		</template>
	</ul>
</template>
<script>
export default {
	name: 'AppSubmenu',
	props: {
		items: Array,
		root: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeIndex : null,
			currentPath: this.$router.currentRoute.path,
			routeAdd: this.$router.currentRoute.path.split('/add'),
			routeView: this.$router.currentRoute.path.split('/view'),
			routeEdit: this.$router.currentRoute.path.split('/edit'),
			myInterval: setInterval(this.loadActiveMenu, 100),
			routeArr: []
		}
	},
	watch: {
		"$route"() {
			this.routeAdd = this.$route.path.split('/add');
			this.routeView = this.$route.path.split('/view');
			this.routeEdit = this.$route.path.split('/edit');
			this.routeArr = [this.$route.path, ...this.routeAdd, ...this.routeEdit, ...this.routeView];
			this.loadActiveMenu(false);
		}
	},
	mounted() {
		this.routeArr = [this.$router.currentRoute.path, ...this.routeAdd, ...this.routeEdit, ...this.routeView];
	},
	created() {
		// this.loadActiveMenu();
	},
	methods: {
		loadActiveMenu(activeMenu=true) {
			var activeRouteNewArr = [];
			this.$nextTick(async () => {
				this.$nextTick( async () => {
					var activeRoute = document.querySelectorAll('.active-route');
					for (let i=0; i < activeRoute.length; i++) {
						var activeRouteNew = activeRoute[i];
						if (this.routeArr.includes(activeRouteNew.getAttribute("href"))) {
							if (activeRouteNew && activeMenu) {
								var parentRoute = activeRouteNew.closest('ul');
								if (parentRoute) {
									var grandParentRoute = parentRoute.closest('li');
									if (grandParentRoute) {
										var childElement = grandParentRoute.children;
										if (!this.$commonFuction.isEmpty(childElement)) {
											clearInterval(this.myInterval);
											parentRoute.click();
											for (let c = 0; c < childElement.length; c++) {
												await childElement[c].click();
											}
										}
									}
								}
							}
						} else {
							activeRouteNewArr.push(activeRouteNew);
						}
					}
					activeRouteNewArr.map(m => {
						m.classList.remove("active-route");
					})
				})
			});
		},
		onMenuItemClick(event, item, index) {
			if (item.disabled) {
				event.preventDefault();
				return;
            }
            
            if (!item.to && !item.url) {
                event.preventDefault();
            }

			//execute command
			if (item.command) {
                item.command({originalEvent: event, item: item});
			}

			this.activeIndex = index === this.activeIndex ? null : index;
			this.currentPath = null;
			this.routeArr = [];
			//chặn href load thêm lần nữa
			event.preventDefault();
			this.$emit('menuitem-click', {
				originalEvent: event,
				item: item
			});
		}
	},
	components: {
		"AppSubmenu": this
	}
}
</script>

<style scoped>
	.custom-sub{
		font-size: 16px;
	}
</style>