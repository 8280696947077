<!--<template>-->
<!--  <div class="layout-footer">-->
<!--    <span class="footer-text" style="margin-right: 5px"-->
<!--      ><strong-->
<!--        >Copyright &copy; {{ year }}-->
<!--        {{ year === currentYear ? null : "-" + currentYear }} Lê Mây.</strong-->
<!--      >-->
<!--      All rights reserved.</span-->
<!--    >-->
<!--  </div>-->
<!--</template>-->

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      year: 2023,
    };
  },
};
</script>

<style scoped></style>
